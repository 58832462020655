// /**
//  * Axios config  setup
//  * Set interceptor for global api response error handling
//  * Set access token in headers
//  */
import axios from "axios";
import { API_BASE } from './AppConstant'
import store from "../store"
import { LOGOUT } from "../store/Login/Types"

(function (axios) {
    let isRequestedToken = false

    axios.interceptors.request.use(
        config => {
            const modifiedConfig = config;
            const token = JSON.parse(localStorage.getItem("authToken"));
            if (token) {
                modifiedConfig.headers.Authorization = token;
            } else {
                modifiedConfig.headers.Authorization = null;
            }
            return modifiedConfig;
        },
        error => {
            //      setBrowserClose(false);
            return Promise.reject(error);
        },
    );


    axios.interceptors.response.use(null, (error) => {
        let originalRequest = error.config;

        if (error.response.status === 401) {
            store.dispatch({
                type: LOGOUT
            });
        }

        if (error.response.status === 408 && !originalRequest._retry && !isRequestedToken) {
            isRequestedToken = true
            originalRequest._retry = true;
            const authToken = JSON.parse(localStorage.getItem("authToken"));
            return axios.post(`${API_BASE}auth/refreshToken`,
                {
                    token: authToken
                })
                .then(res => {
                    axios.defaults.headers.common['Authorization'] = res.data.data.access_token
                    localStorage.setItem("authToken", JSON.stringify(res.data.data.access_token))
                    isRequestedToken = false
                    return axios(originalRequest);
                })

        }

        return Promise.reject(error);

        // if (error.response) {

        //     if (error.response.status === 401 || error.response.status === 402) {
        //         store.dispatch({
        //             type: LOGOUT
        //         });
        //         return Promise.reject(error);
        //     } else return Promise.reject(error);
        // } else if (error.request) {
        //     let err = {
        //         response: {
        //             data: {
        //                 message: "Something went wrong,Please try again later!!!"
        //             }
        //         }
        //     }
        //     return Promise.reject(err);
        // }
    });
})(axios);

// access_token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2MzU0MDYxNzIsImV4cCI6MTYzNTQwNjE4N30.R2P20DpY5xgMJUxDcS11LoEaq8SAjxtlah8t_Sv1k7U"
// access_token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2MzU0MDYzOTksImV4cCI6MTYzNTQwNjQxNH0.gOgFNtqgQjIcOp5TyfPOlg5WjOUirxcKIPxvZaee2sg"
// "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2MzU0MDYzOTksImV4cCI6MTYzNTQwNjQxNH0.gOgFNtqgQjIcOp5TyfPOlg5WjOUirxcKIPxvZaee2sg"