import * as ACTION_TYPES from './Types'

const initialSortIconState = {
    activeName: false,
    activeName1: false,
    activeLastName1: false,
    activeLastName: false,
    activeEmail: false,
    activeEmail1: false,
    activeDate: false,
    activeDate1: false,
    activePhone: false,
    activePhone1: false,
    activeService: false,
    activeService1: false,
    activeStatus: false,
    activeStatus1: false,
    activeApprove: false,
    activeApprove1: false,
}

const initialState = {
    message: "",
    error: null,
    loading: false,
    userList: { filteredCount: 0, totalCount: 0 },
    user: {},
    paymentData: {},
    wallet: {},
    page: 1,
    limit: 10,
    paymentPage: 1,
    paymetnLimit: 10,
    paymentSort: '',
    paymentColumn: '',
    search: '',
    column: '',
    sort: '',
    activeSortIcon: initialSortIconState
}

export const TrainerReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.FETCH_TRAINER_LIST_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ACTION_TYPES.FETCH_TRAINER_LIST_SUCCESS:
            return {
                ...state,
                userList: action.payload.data,
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.FETCH_TRAINER_PAYMENT_LIST_SUCCESS:
            return {
                ...state,
                paymentData: action.payload.data,
                message: action.payload.message,
                loading: false,
                error: null
            }
        case ACTION_TYPES.TRAINER_PAGE_CHANGE:
            return {
                ...state,
                page: action.payload,
                loading: false,

            }
        case ACTION_TYPES.TRAINER_PAYMENT_PAGE_CHANGE:
            return {
                ...state,
                paymentPage: action.payload,
                loading: false,

            }
        case ACTION_TYPES.FETCH_TRAINER_WALLET_SUCCESS:
            return {
                ...state,
                wallet: action.payload.data,
                loading: false,
            }
        case ACTION_TYPES.FETCH_TRAINER_INFO_SUCCESS:
            return {
                ...state,
                user: action.payload.data,
                message: action.payload.message,
                isEdited: false,
                error: null
            }
        case ACTION_TYPES.TRAINER_LIMIT_CHANGE:
            return {
                ...state,
                limit: action.payload,
                loading: false,

            }
        case ACTION_TYPES.TRAINER_PAYMENT_LIMIT_CHANGE:
            return {
                ...state,
                paymetnLimit: action.payload,
                loading: false,

            }
        case ACTION_TYPES.FETCH_TRAINER_LIST_ERROR:
        case ACTION_TYPES.FETCH_TRAINER_PAYMENT_LIST_ERROR:
        case ACTION_TYPES.FETCH_TRAINER_INFO_ERROR:
        case ACTION_TYPES.FETCH_TRAINER_WALLET_ERROR:
            return {
                ...state,
                loading: false,
                message: action.payload.data.message,
                error: action.payload.data.message,
                isEdited: false
            }
        case ACTION_TYPES.TRAINER_PARAMS_CHANGE:
            return {
                ...state,
                loading: false,
                [action.payload.key]: action.payload.value
            }
        case ACTION_TYPES.TRAINER_SORT_ICON_ACTIVE:
            return {
                ...state,
                activeSortIcon: {
                    ...initialSortIconState,
                    [action.payload]: true
                }
            }
        default:
            return state
    }
}

