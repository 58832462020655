//FETCH_LIST Actions
export const FETCH_TRAINER_LIST_SUCCESS = "FETCH_TRAINER_LIST_SUCCESS";
export const FETCH_TRAINER_LIST_REQUEST = "FETCH_TRAINER_LIST_REQUEST";
export const FETCH_TRAINER_LIST_ERROR = "FETCH_TRAINER_LIST_ERROR";
//PAGE_CHANGE Actions
export const TRAINER_PAGE_CHANGE = "TRAINER_PAGE_CHANGE"

//LIMIT_CHANGE Actions
export const TRAINER_LIMIT_CHANGE = "TRAINER_LIMIT_CHANGE"
//DEL_TRAINER Actions
export const DEL_TRAINER_SUCCESS = "DEL_TRAINER_SUCCESS";
export const DEL_TRAINER_ERROR = "DEL_TRAINER_ERROR";
//TRAINER_ACTIVE_STATUS Actions
export const TRAINER_ACTIVE_STATUS_SUCCESS = "TRAINER_ACTIVE_STATUS_SUCCESS";
export const TRAINER_ACTIVE_STATUS_ERROR = "TRAINER_ACTIVE_STATUS_ERROR";
//TRAINER_ACTIVE_STATUS Actions
export const TRAINER_APPROVAL_SUCCESS = "TRAINER_APPROVAL_SUCCESS";
export const TRAINER_APPROVAL_ERROR = "TRAINER_APPROVAL_ERROR";
//FETCH_TRAINER Actions
export const FETCH_TRAINER_INFO_SUCCESS = "FETCH_TRAINER_INFO_SUCCESS";
export const FETCH_TRAINER_INFO_ERROR = "FETCH_TRAINER_INFO_ERROR";

//FETCH_LIST Actions
export const FETCH_TRAINER_PAYMENT_LIST_SUCCESS = "FETCH_TRAINER_PAYMENT_LIST_SUCCESS";
export const FETCH_TRAINER_PAYMENT_LIST_REQUEST = "FETCH_TRAINER_PAYMENT_LIST_REQUEST";
export const FETCH_TRAINER_PAYMENT_LIST_ERROR = "FETCH_TRAINER_PAYMENT_LIST_ERROR";

//PAGE_CHANGE Actions
export const TRAINER_PAYMENT_PAGE_CHANGE = "TRAINER_PAYMENT_PAGE_CHANGE"

//LIMIT_CHANGE Actions
export const TRAINER_PAYMENT_LIMIT_CHANGE = "TRAINER_PAYMENT_LIMIT_CHANGE"

//FETCH_LIST Actions
export const FETCH_TRAINER_WALLET_SUCCESS = "FETCH_TRAINER_WALLET_SUCCESS";
export const FETCH_TRAINER_WALLET_REQUEST = "FETCH_TRAINER_WALLET_REQUEST";
export const FETCH_TRAINER_WALLET_ERROR = "FETCH_TRAINER_WALLET_ERROR";

// Change TRAINER params 
export const TRAINER_PARAMS_CHANGE = "TRAINER_PARAMS_CHANGE"

// Change TRAINER sort icon active 
export const TRAINER_SORT_ICON_ACTIVE = "TRAINER_SORT_ICON_ACTIVE" 