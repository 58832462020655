//FETCH_LIST Actions
export const FETCH_LIST_SUCCESS = "FETCH_LIST_SUCCESS";
export const FETCH_LIST_REQUEST = "FETCH_LIST_REQUEST";
export const FETCH_LIST_ERROR = "FETCH_LIST_ERROR";
//PAGE_CHANGE Actions
export const USER_PAGE_CHANGE = "USER_PAGE_CHANGE"

//LIMIT_CHANGE Actions
export const USER_LIMIT_CHANGE = "USER_LIMIT_CHANGE"
//DEL_USER Actions
export const DEL_USER_SUCCESS = "DEL_USER_SUCCESS";
export const DEL_USER_ERROR = "DEL_USER_ERROR";
//USER_ACTIVE_STATUS Actions
export const USER_ACTIVE_STATUS_SUCCESS = "USER_ACTIVE_STATUS_SUCCESS";
export const USER_ACTIVE_STATUS_ERROR = "USER_ACTIVE_STATUS_ERROR";
//FETCH_USER Actions
export const FETCH_USER_INFO_SUCCESS = "FETCH_USER_INFO_SUCCESS";
export const FETCH_USER_INFO_ERROR = "FETCH_USER_INFO_ERROR";

export const FETCH_USER_PAYMENT_LIST_SUCCESS = "FETCH_USER_PAYMENT_LIST_SUCCESS";
export const FETCH_USER_PAYMENT_LIST_REQUEST = "FETCH_USER_PAYMENT_LIST_REQUEST";
export const FETCH_USER_PAYMENT_LIST_ERROR = "FETCH_USER_PAYMENT_LIST_ERROR";

//PAGE_CHANGE Actions
export const USER_PAYMENT_PAGE_CHANGE = "USER_PAYMENT_PAGE_CHANGE"

//LIMIT_CHANGE Actions
export const USER_PAYMENT_LIMIT_CHANGE = "USER_PAYMENT_LIMIT_CHANGE"

// Change user params 
export const USER_PARAMS_CHANGE = "USER_PARAMS_CHANGE"

// Change user sort icon active 
export const USER_SORT_ICON_ACTIVE = "USER_SORT_ICON_ACTIVE" 