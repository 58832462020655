import { changeState } from "./common/changeStateReducer"
import { loginReducer } from "./Login/Reducer"
import { adminReducer } from "./adminProfile/Reducer"
import { UserReducer } from "./User/Reducer"
import { TrainerReducer } from "./Trainer/Reducer"
import { AgentReducer } from "./CMS/Reducer"
import { reducer as formReducer } from 'redux-form'
import { reducer as toastrReducer } from 'react-redux-toastr'
import { combineReducers } from "redux"
export const rootReducer = combineReducers({
    changeState: changeState,
    toastr: toastrReducer,
    form: formReducer,
    login: loginReducer,
    admin: adminReducer,
    user: UserReducer,
    trainer: TrainerReducer,
    cms: AgentReducer,
});